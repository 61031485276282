import React, { lazy } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import AccessibleNavigationAnnouncer from "./components/AccessibleNavigationAnnouncer";
import history from "./history";
import PrivateRoute from "./privateRoutes";
const Layout = lazy(() => import("./containers/Layout"));
const Validate = lazy(() => import("./pages/Validate"));
const CreateAccount = lazy(() => import("./pages/CreateAccount"));
const Terms = lazy(() => import("./pages/TermsandConditions"));
const Approval = lazy(() => import("./pages/Approval"));
function App() {
  return (
    <>
      <Router history={history}>
        <AccessibleNavigationAnnouncer />
        <Switch>
          <Route path="/validate" component={Validate} />
          <Route path="/create-account" component={CreateAccount} />
          <Route path="/terms-condition" component={Terms} />
          <Route path="/approval" component={Approval} />

          <PrivateRoute path="/" component={Layout} />

          <Redirect exact from="/" to="/create-account" />
        </Switch>
      </Router>
    </>
  );
}

export default App;
